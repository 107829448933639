import React, { useEffect } from 'react';
import Layout from "../components/layout";
import { Link } from "gatsby";
import Footer from '../components/footer';
import BgImage from '../images/bg/solutions-page-banner.jpg';
import SocialIconsFooter from '../components/social-icons-footer';
import { StaticImage } from 'gatsby-plugin-image';
import s1 from '../images/pragicts-software-development-process.png';

const WebsiteDeliveryScope = () => {

    useEffect(() => {
        // Function to get query parameter value by name
        function getQueryParam(name) {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(name);
        }

        // Get the heading parameter
        const headingText = getQueryParam('url');

        // Update the heading if the parameter is present
        if (headingText) {
            const headingElement = document.getElementById('page-heading');
            if (headingElement) {
                headingElement.textContent = headingText;
            }
        }
    }, []); // Empty dependency array ensures this runs once on mount

    return (
        <Layout pageTitle="PragICTS | Website Hosting Down" og_desc="">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link">
                            <i className="fal fa-arrow-left"></i> <span>Back to home</span>
                        </Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem" data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>WEBSITE HOSTING  <br />
                                    <span>DOWN</span>
                                </h2>
                                <p></p>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link">
                                <span>Let's Start</span> <i className="fal fa-long-arrow-down"></i>
                            </a>
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="sec1">
                        <div className="section-subtitle left-pos" data-scrollax="properties: { translateY: '-250px' }">
                            <span>//</span>DOWN
                        </div>
                        <div className="container main-about">
                            <p  style={{ paddingBottom: '20px' }}>
                                <h6 style={{ fontSize: '16px' }}>
                                    <span style={{ fontWeight: 'bold' }}>PragICTS website hosting is down for the following website hosting</span><br /><br />
                                    <br />
                                    <p style={{ fontSize: '16px',color:'black' }}  id='page-heading'>URL</p>
                                </h6>
                            </p>
                            <p>
                                <h6 style={{ fontSize: '14px' }}>
                                    <span style={{ fontWeight: 'bold' }}>Contact Details</span><br />
                                    <a href='mailto:support@pragicts.com' target='_blank' rel='noopener noreferrer'>support@pragicts.com</a>
                                </h6>
                            </p>
                        </div>

                        <div className="bg-parallax-module" data-position-top="50" data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div className="bg-parallax-module" data-position-top="40" data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80" data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="bg-parallax-module" data-position-top="95" data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div className="sec-lines"></div>
                    </section>

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    );
}

export default WebsiteDeliveryScope;
